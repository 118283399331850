const INDEX_ID_BY_SOURCE = {
  us: 'application_number',
  eu: 'product_number',
  'us-devices': 'application_number',
  ct: 'nct_id',
  ca: 'vin',
  hpra: 'product_number',
  jp: 'yj_code',
  au: 'vin',
  euct: 'euct_id',
  hma: 'vin_number',
  mhra: 'vin',
  chn: 'vin'
};

const flatten = countDataSynonyms => {
  try {
    return countDataSynonyms.reduce((flat, toFlatten) => {
      return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const updateSynonymsWithMatcheDocuments = (countDataSynonyms, results) => {
  try {
    const synonymsList = flatten(countDataSynonyms);
    const result = {};
    const ids = new Set();
    synonymsList.forEach(i => {
      if (!(i in results)) {
        result[i] = { count: 0, id: [] };
      } else {
        result[i] = results[i];
        results[i].id.forEach(j => {
          ids.add(j);
        });
      }
    });
    return { synonyms: result, id: Array.from(ids) };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const traverseTree = (tree, results, id) => {
  try {
    let uniqueDocuments = new Set();
    const treeSynonymsCount = updateSynonymsWithMatcheDocuments(tree.synonyms, results);
    tree.synonyms = treeSynonymsCount.synonyms;
    uniqueDocuments = [...new Set([...Array.from(uniqueDocuments), ...treeSynonymsCount.id])];
    tree.children.forEach(child => {
      uniqueDocuments = [
        ...new Set([...Array.from(uniqueDocuments), ...traverseTree(child, results, id)])
      ];
    });
    tree.id = uniqueDocuments;
    tree.count = tree.id.length;
    return uniqueDocuments;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

const mapSynonymsWithMatchedResults = (countData, resultsState) => {
  try {
    const results = {};
    const IndexId = [];
    let applicationResults = {};
    if (resultsState.viewType !== 'ct') {
      applicationResults = resultsState.applicationResults;
    } else {
      applicationResults = {
        [resultsState?.ctSource]: {
          results: resultsState?.synonymsList ?? []
        }
      };
    }
    Object.entries(applicationResults).forEach(([key, value]) => {
      const id = INDEX_ID_BY_SOURCE[key];
      IndexId.push(id);
      value.results.forEach(i => {
        if ('matched_synonyms' in i) {
          i.matched_synonyms.forEach(j => {
            if (!(j in results)) {
              results[j] = { count: 0, id: [] };
            }
            results[j].count += 1;
            results[j].id.push(i[id]);
          });
        }
      });
    });

    const synonymsCount = updateSynonymsWithMatcheDocuments(countData.synonyms, results);
    countData.synonyms = synonymsCount.synonyms;

    countData.trees.forEach(i => {
      IndexId.forEach(id => {
        i[id] = traverseTree(i, results, id);
        i.count = i.id.length;
      });
    });

    Object.entries(countData.suppl_concept_terms).forEach(([key, value]) => {
      if (IndexId.length !== 0) {
        IndexId.forEach(() => {
          const supplConceptTermsCount = updateSynonymsWithMatcheDocuments(value, results);
          countData.suppl_concept_terms[key] = supplConceptTermsCount.synonyms;
        });
      } else {
        const supplConceptTermsCount = updateSynonymsWithMatcheDocuments(value, results);
        // eslint-disable-next-line no-param-reassign
        countData.suppl_concept_terms[key] = supplConceptTermsCount.synonyms;
      }
    });

    Object.entries(countData.vivpro_suppl_terms).forEach(([key, value]) => {
      IndexId.forEach(() => {
        const meshEntryTermsCount = updateSynonymsWithMatcheDocuments(
          value.mesh_entry_terms,
          results
        );
        const supplTermsCount = updateSynonymsWithMatcheDocuments(value.suppl_terms, results);
        countData.vivpro_suppl_terms[key].mesh_entry_terms = meshEntryTermsCount.synonyms;
        countData.vivpro_suppl_terms[key].suppl_terms = supplTermsCount.synonyms;
      });
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

const getSynonymsTreeCount = (treeData, resultsState) => {
  const countData = { ...treeData };
  mapSynonymsWithMatchedResults(countData, resultsState);
  return { countData };
};

export default getSynonymsTreeCount;
