const getStyleFromAppliedFilter = (appliedStyle: Array<any>) => {
  const styleObject: any = {};
  const styleState = [...appliedStyle];
  styleState.forEach((item: any) => {
    if (item.type === 'multi-value-dropdown') {
      item.options?.forEach((opt: any) => {
        if (opt.key === item.value) {
          styleObject[item.id] = opt.value;
        }
      });
      // eslint-disable-next-line no-param-reassign
      item.customColor = false;
    } else {
      styleObject[item.id] = item.value;
    }
  });

  styleState.forEach((item: any) => {
    if (item.type === 'checkbox' && !item.value) {
      // Loop for the disabled keys by delete them from styleObject
      item.disabled.forEach((row: string) => {
        delete styleObject[row];
      });
    }
  });

  return styleObject;
};

export const addDefaultPlacement = (sections: any[]) => {
  const prevX = 0;
  let nextY = 0;

  if (!sections) {
    return [];
  }
  const updatedSections = sections.map(section => {
    if (section.style.placement.y) {
      nextY += section.style.placement.h;
      return section;
    }

    const newSectionData = {
      ...section,
      style: {
        ...section.style,
        placement: {
          ...section.style.placement,
          x: prevX,
          y: nextY
        }
      }
    };
    nextY += section.style.placement.h;

    return newSectionData;
  });

  return updatedSections;
};

export const getUpdatedLayout = (prevLayout: any, newSection: any) => {
  const layout = {
    ...prevLayout,
    sections: addDefaultPlacement([...(prevLayout?.sections || []), newSection])
  };

  return layout;
};

export default getStyleFromAppliedFilter;
