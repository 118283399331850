const APPROVAL_STATUS_TAGS: { [key: string]: any } = {
  approval_history: [
    {
      label: 'Accelerated Approval',
      initial: 'AA',
      hoverText: 'accelerated_approval_text',
      fieldName: 'accelerated_approval'
    },
    {
      label: 'Breakthrough Designation',
      initial: 'BT',
      hoverText: 'breakthrough_text',
      fieldName: 'breakthrough'
    },
    {
      label: 'Fast Track',
      initial: 'FT',
      hoverText: 'fast_track_text',
      fieldName: 'fast_track'
    },
    {
      label: 'Orphan Designation',
      initial: 'OR',
      hoverText: 'orphan_status_text',
      fieldName: 'orphan_status'
    },
    {
      label: 'Review Standard',
      initial: 'S',
      hoverText: 'review_standard_text',
      fieldName: 'review_standard',
      showOnlyTrue: true
    },
    {
      label: 'Review Priority',
      initial: 'P',
      hoverText: 'review_priority_text',
      fieldName: 'review_priority',
      showOnlyTrue: true
    },
    {
      label: 'Review Other',
      initial: 'O',
      hoverText: 'review_other_text',
      fieldName: 'review_other',
      showOnlyTrue: true
    },
    {
      label: 'Complete Response',
      initial: 'CR',
      hoverText: 'complete_response_text',
      fieldName: 'complete_response'
    },
    {
      label: 'RTOR',
      initial: 'RT',
      hoverText: 'rtor_text',
      fieldName: 'rtor'
    },
    {
      label: 'REMS',
      initial: 'RM',
      hoverText: 'rems_text',
      fieldName: 'rems_flag'
    },
    {
      label: 'Boxed Warning',
      initial: 'BW',
      hoverText: 'submission_boxed_warning_text',
      fieldName: 'submission_boxed_warning_flag'
    }
  ],
  approval_history_ca: [
    {
      label: 'NOC with Condition',
      initial: 'NC',
      hoverText: 'noc_w_condition_flag_text',
      fieldName: 'noc_w_condition_flag'
    },
    {
      label: 'Priority Review',
      initial: 'PR',
      hoverText: 'priority_review_flag_text',
      fieldName: 'priority_review_flag'
    }
  ],
  approval_history_us_devices: [
    {
      label: 'Expedited Review',
      initial: 'ER',
      hoverText: 'expedited_review_flag_text',
      fieldName: 'expedited_review_flag'
    },
    {
      label: 'Combination Product',
      initial: 'CO',
      hoverText: 'combination_product_text',
      fieldName: 'combination_product'
    },
    {
      label: 'Third Party',
      initial: 'TP',
      hoverText: 'third_party_flag_text',
      fieldName: 'third_party_flag'
    },
    {
      label: 'GMP Exempt',
      initial: 'GM',
      hoverText: 'gmp_exempt_flag_text',
      fieldName: 'gmp_exempt_flag'
    },
    {
      label: 'Implant',
      initial: 'IM',
      hoverText: 'implant_flag_text',
      fieldName: 'implant_flag'
    },
    {
      label: 'Life Sustain Support',
      initial: 'LS',
      hoverText: 'life_sustain_support_flag_text',
      fieldName: 'life_sustain_support_flag'
    },
    {
      label: 'Companion Diagnostic Devices',
      initial: 'CD',
      hoverText: 'companion_diagnostic_devices_text',
      fieldName: 'companion_diagnostic_devices_status'
    }
  ],
  approval_history_chn_drugs: [
    {
      label: 'Priority Review',
      initial: 'PR',
      hoverText: 'priority_review_flag_text',
      fieldName: 'priority_review_flag'
    },
    {
      label: 'Special Approval',
      initial: 'SA',
      hoverText: 'special_approval_flag_text',
      fieldName: 'special_approval_flag'
    },
    {
      label: 'Breakthrough Therapy',
      initial: 'BT',
      hoverText: 'breakthrough_therapy_flag_text',
      fieldName: 'breakthrough_therapy_flag'
    }
  ]
};

const REMS_VERSIONS_TAGS: { [key: string]: any } = {
  rems: [
    {
      label: 'Moved to Shared System',
      initial: 'SS',
      hoverText: 'moved_to_shared_system_flag_text',
      fieldName: 'moved_to_shared_system_flag'
    },
    {
      label: 'Medication Guide',
      initial: 'MG',
      hoverText: 'medication_guide_flag_text',
      fieldName: 'medication_guide_flag'
    },
    {
      label: 'Communication Plan',
      initial: 'CP',
      hoverText: 'communication_plan_flag_text',
      fieldName: 'communication_plan_flag'
    },
    {
      label: 'Currently Approved',
      initial: 'CA',
      hoverText: 'current_approved_flag_text',
      fieldName: 'current_approved_flag'
    },
    {
      label: 'Implementation System',
      initial: 'IS',
      hoverText: 'implementation_system_flag_text',
      fieldName: 'implementation_system_flag'
    },
    {
      label: 'Prescriber Certification',
      initial: 'PC',
      hoverText: 'prescriber_certification_flag_text',
      fieldName: 'prescriber_certification_flag'
    },
    {
      label: 'Prescriber Training',
      initial: 'PT',
      hoverText: 'prescriber_training_flag_text',
      fieldName: 'prescriber_training_flag'
    },
    {
      label: 'Released',
      initial: 'RL',
      hoverText: 'released_flag_text',
      fieldName: 'released_flag'
    },
    {
      label: 'Revision',
      initial: 'RV',
      hoverText: 'revision_flag_text',
      fieldName: 'revision_flag'
    }
  ]
};
export const VERSIONS_TAGS = REMS_VERSIONS_TAGS;
export default APPROVAL_STATUS_TAGS;
