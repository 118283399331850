/* eslint-disable camelcase */
export const ARIA_SEARCH_SUBTEXT = {
  us: ' Summary Basis of Approval Documents',
  ca: ' CA HPFB Documents',
  eu: ' EMA Documents',
  'us-devices': ' CDRH Approval Summary Documents',
  adcomm: ' AdComm Documents',
  guidance: ' Guidance Documents',
  'ema-guidance': ' EMA Guidance Documents',
  'canada-guidance': ' CA Guidance Documents',
  'canada-consultation': ' CA Consultations Documents',
  'fda-letters': ' FDA Warnings and Untitled Letters',
  uk: ' NICE Guidance, Advice and Quality Standards',
  ct: ' US NLM ClinicalTrials.gov',
  'ema-pips': ' EU EMA Pediatric Investigation Plans',
  'fda-written-request': ' FDA Written Requests',
  chmp: ' CHMP Meetings',
  hpra: ' HPRA Documents',
  hma: ' HMA Documents',
  jp: ' Package Inserts',
  'dd-tools': ' Drug Development Tools',
  euctr: ' EU Clinical Trials Register',
  ema_orphan: ' EMA Orphan Designations',
  pdco: ' PDCO',
  mhra: 'MHRA Documents',
  prac: ' PRAC',
  'sec-edgar': ' SEC',
  sec: ' SEC',
  au: 'Australia TGA Documents',
  chn: ' CDE Documents'
};

export const TIMEOUT_DURATION_ARIA_BANNER = 1000;

const sort_by_us_eu_devices_sources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Approval Year: Newest', value: 'approval_date', sortType: 'desc' },
  { id: 'Approval Year: Oldest', value: 'approval_date', sortType: 'asc' },
  { id: 'Active Ingredient: A -> Z', value: 'active_ingredient', sortType: 'asc' },
  { id: 'Active Ingredient: Z -> A', value: 'active_ingredient', sortType: 'desc' }
];
const sort_by_ema_guidance_sources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Last Published Date: Newest', value: 'last_published_date', sortType: 'desc' },
  { id: 'Last Published Date: Oldest', value: 'last_published_date', sortType: 'asc' },
  { id: 'Title: A -> Z', value: 'title', sortType: 'asc' },
  { id: 'Title: Z -> A', value: 'title', sortType: 'desc' }
];
const sort_by_fda_written_request_sources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Last Determination Date: Newest', value: 'determination_date', sortType: 'desc' },
  { id: 'Last Determination Date: Oldest', value: 'determination_date', sortType: 'asc' },
  { id: 'Title: A -> Z', value: 'title', sortType: 'asc' },
  { id: 'Title: Z -> A', value: 'title', sortType: 'desc' }
];
const sort_by_guidance_sources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Field issue date: Newest', value: 'field_issue_datetime', sortType: 'desc' },
  { id: 'Field issue date: Oldest', value: 'field_issue_datetime', sortType: 'asc' },
  { id: 'Title: A -> Z', value: 'title', sortType: 'asc' },
  { id: 'Title: Z -> A', value: 'title', sortType: 'desc' }
];
const sort_by_adcomm_sources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Meeting date: Newest', value: 'adcom_meeting_start_date', sortType: 'desc' },
  { id: 'Meeting date: Oldest', value: 'adcom_meeting_start_date', sortType: 'asc' },
  { id: 'Active Ingredient: A -> Z', value: 'active_ingredient', sortType: 'asc' },
  { id: 'Active Ingredient: Z -> A', value: 'active_ingredient', sortType: 'desc' }
];
const sort_by_uk_sources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Published: Newest', value: 'published', sortType: 'desc' },
  { id: 'Published: Oldest', value: 'published', sortType: 'asc' },
  { id: 'Last Updated: Newest', value: 'last_updated', sortType: 'desc' },
  { id: 'Last Updated: Oldest', value: 'last_updated', sortType: 'asc' },
  { id: 'Title: A -> Z', value: 'title', sortType: 'asc' },
  { id: 'Title: Z -> A', value: 'title', sortType: 'desc' }
];
const sortByClinicalTrials = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Start Date: Newest', value: 'start_date', sortType: 'desc' },
  { id: 'Start Date: Oldest', value: 'start_date', sortType: 'asc' },
  { id: 'Title: A -> Z', value: 'brief_title', sortType: 'asc' },
  { id: 'Title: Z -> A', value: 'brief_title', sortType: 'desc' }
];

const sortByEmaPips = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Active Ingredient: A -> Z', value: 'active_ingredient', sortType: 'asc' },
  { id: 'Active Ingredient: Z -> A', value: 'active_ingredient', sortType: 'desc' }
];

const sortByCanadaSource = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Submission Approval Date: Newest', value: 'submission-approval-date', sortType: 'desc' },
  { id: 'Submission Approval Date: Oldest', value: 'submission-approval-date', sortType: 'asc' },
  { id: 'Active Ingredient: A -> Z', value: 'active-ingredient', sortType: 'asc' },
  { id: 'Active Ingredient: Z -> A', value: 'active-ingredient', sortType: 'desc' }
];

const sortByAustraliaSource = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Active Ingredient: A -> Z', value: 'active-ingredients', sortType: 'asc' },
  { id: 'Active Ingredient: Z -> A', value: 'active-ingredients', sortType: 'desc' }
];

const sortByHPRASources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Active Ingredient: A -> Z', value: 'active_ingredient', sortType: 'asc' },
  { id: 'Active Ingredient: Z -> A', value: 'active_ingredient', sortType: 'desc' }
];

const sortByHMASources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Active Ingredient: A -> Z', value: 'active_ingredient', sortType: 'asc' },
  { id: 'Active Ingredient: Z -> A', value: 'active_ingredient', sortType: 'desc' }
];

const sortByChmpSource = [{ id: 'Relevance: Very High -> Low', value: 'default' }];

const sortByCanadaGuidanceSources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Field issue date: Newest', value: 'issued_date', sortType: 'desc' },
  { id: 'Field issue date: Oldest', value: 'issued_date', sortType: 'asc' },
  { id: 'Title: A -> Z', value: 'title', sortType: 'asc' },
  { id: 'Title: Z -> A', value: 'title', sortType: 'desc' }
];
const sortByCanadaConsultationSources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Field issue date: Newest', value: 'issued_date', sortType: 'desc' },
  { id: 'Field issue date: Oldest', value: 'issued_date', sortType: 'asc' },
  { id: 'Title: A -> Z', value: 'title', sortType: 'asc' },
  { id: 'Title: Z -> A', value: 'title', sortType: 'desc' }
];

const sortByPMDASource = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Submission Approval Date: Newest', value: 'approval-date', sortType: 'desc' },
  { id: 'Submission Approval Date: Oldest', value: 'approval-date', sortType: 'asc' },
  { id: 'Active Ingredient: A -> Z', value: 'active-ingredient', sortType: 'asc' },
  { id: 'Active Ingredient: Z -> A', value: 'active-ingredient', sortType: 'desc' }
];

const sortByFdaLetterSources = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Field issue date: Newest', value: 'issue_date', sortType: 'desc' },
  { id: 'Field issue date: Oldest', value: 'issue_date', sortType: 'asc' },
  { id: 'Title: A -> Z', value: 'title', sortType: 'asc' },
  { id: 'Title: Z -> A', value: 'title', sortType: 'desc' }
];

const sortByDdtSource = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Field stage date: Newest', value: 'stage_date', sortType: 'desc' },
  { id: 'Field stage date: Oldest', value: 'stage_date', sortType: 'asc' },
  { id: 'Title: A -> Z', value: 'title', sortType: 'asc' },
  { id: 'Title: Z -> A', value: 'title', sortType: 'desc' }
];

const sortByEmaOdSource = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Field stage date: Newest', value: 'stage_date', sortType: 'desc' },
  { id: 'Field stage date: Oldest', value: 'stage_date', sortType: 'asc' },
  { id: 'Title: A -> Z', value: 'title', sortType: 'asc' },
  { id: 'Title: Z -> A', value: 'title', sortType: 'desc' }
];

const sortByPdcoSource = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Title: A -> Z', value: 'original-file-name', sortType: 'asc' }
];

const sortByMHRASource = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Approval Year: Newest', value: 'approval_date', sortType: 'desc' },
  { id: 'Approval Year: Oldest', value: 'approval_date', sortType: 'asc' },
  { id: 'Active Ingredient: A -> Z', value: 'active_ingredient', sortType: 'asc' },
  { id: 'Active Ingredient: Z -> A', value: 'active_ingredient', sortType: 'desc' }
];
const sortByPracSource = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Title: A -> Z', value: 'original-file-name', sortType: 'asc' }
];

const sortBySecEdgarSource = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Filing Date', value: 'filing_date', sortType: 'desc' },
  { id: 'Filing Date', value: 'filing_date', sortType: 'asc' }
];

const sortByCDESource = [
  { id: 'Relevance: Very High -> Low', value: 'default' },
  { id: 'Title: A -> Z', value: 'title', sortType: 'asc' }
];
export const DROPDOWN_MENU_OPTIONS = {
  download: {
    ...Object.assign(
      ...[
        'us',
        'eu',
        'ca',
        'us-devices',
        'guidance',
        'adcomm',
        'uk',
        'ct',
        'ema-pips',
        'ema-guidance',
        'canada-guidance',
        'canada-consultation',
        'fda-letters',
        'fda-written-request',
        'chmp',
        'hpra',
        'hma',
        'jp',
        'dd-tools',
        'euctr',
        'ema_orphan',
        'au',
        'pdco',
        'mhra',
        'prac',
        'sec',
        'chn'
      ].map(k => ({
        [k]: [
          { id: 'Comma separated file', value: 'commaFile' },
          { id: 'Semi colon separated file', value: 'semicolonFile' },
          { id: 'Excel file (.xlsx)', value: 'excelFile' }
        ]
      }))
    )
  },
  sort: {
    ...Object.assign(
      ...['us', 'eu', 'us-devices'].map(k => ({ [k]: sort_by_us_eu_devices_sources }))
    ),
    guidance: sort_by_guidance_sources,
    adcomm: sort_by_adcomm_sources,
    uk: sort_by_uk_sources,
    'ema-guidance': sort_by_ema_guidance_sources,
    'canada-guidance': sortByCanadaGuidanceSources,
    'canada-consultation': sortByCanadaConsultationSources,
    'fda-letters': sortByFdaLetterSources,
    ct: sortByClinicalTrials,
    'ema-pips': sortByEmaPips,
    'fda-written-request': sort_by_fda_written_request_sources,
    ca: sortByCanadaSource,
    chmp: sortByChmpSource,
    hpra: sortByHPRASources,
    hma: sortByHMASources,
    jp: sortByPMDASource,
    'dd-tools': sortByDdtSource,
    euctr: sortByClinicalTrials,
    ema_orphan: sortByEmaOdSource,
    pdco: sortByPdcoSource,
    mhra: sortByMHRASource,
    prac: sortByPracSource,
    'sec-edgar': sortBySecEdgarSource,
    sec: sortBySecEdgarSource,
    au: sortByAustraliaSource,
    chn: sortByCDESource
  }
  // sort: [{ id: 'Sort By Active Ingredient', value: 'active_ingredient' }]
};

export const CATEGORY_BUCKETS = {
  us: [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Labels',
      value: 'Labels'
    },
    {
      label: 'Letters',
      value: 'Letters'
    },
    {
      label: 'Reviews',
      value: 'Reviews'
    },
    {
      label: 'Others',
      value: 'Others'
    }
  ],
  eu: [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Product Info',
      value: 'product-info'
    },
    {
      label: 'Assessment History',
      value: 'assessment-history'
    }
  ],
  adcomm: [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Scientific',
      value: 'Scientific Information'
    },
    {
      label: 'Logistics',
      value: 'Members and Questions'
    },
    {
      label: 'Transcript',
      value: 'Transcript'
    }
  ],
  ca: [
    {
      label: 'Clinical Information',
      value: 'clinical_information'
    },
    {
      label: 'Regulatory Decision Summary',
      value: 'regulatory_decision_summary'
    }
  ],
  hpra: [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Product Info',
      value: 'product_info'
    },
    {
      label: 'Assessment History',
      value: 'assessment_history'
    },
    {
      label: 'Educational Materials',
      value: 'educational_materials'
    }
  ],
  hma: [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Final SPC',
      value: 'Final SPC'
    },
    {
      label: 'Final Product Information',
      value: 'Final Product Information'
    },
    {
      label: 'Final PL',
      value: 'Final PL'
    },
    {
      label: 'PAR',
      value: 'PAR'
    },
    {
      label: 'Final Labelling',
      value: 'Final Labelling'
    },
    {
      label: 'PAR Summary',
      value: 'PAR Summary'
    }
  ]
};

const us_filters = [
  {
    id: 'category_bucket',
    label: 'Category',
    value: 'category_bucket',
    kendraType: 'doc-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 13,
    label: 'Approval Pathway',
    value: 'approval-pathway',
    kendraType: 'group-id-list-class-8',
    filterType: 'checkbox'
  },
  {
    id: 14,
    label: 'Pediatric Use',
    value: 'pediatric_use',
    kendraType: 'group-id-list-class-10',
    filterType: 'checkbox'
  },
  {
    id: 15,
    label: 'Division',
    value: 'division',
    kendraType: 'group-id-list-class-11',
    filterType: 'checkbox'
  },
  {
    id: 16,
    label: 'Office',
    value: 'office',
    kendraType: 'group-id-list-class-12',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    kendraType: 'group-id-list-class-5',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    kendraType: 'group-id-list-class-6',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor',
    kendraType: 'group-id-list-class-7',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'application_number',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Submission',
    value: 'submission',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Trade Name',
    value: 'trade_name',
    kendraType: 'group-id-list-class-9',
    filterType: 'checkbox'
  },
  {
    id: 'data_source',
    label: 'Center',
    value: 'data_source',
    kendraType: 'data-source',
    filterType: 'checkbox'
  },
  {
    id: 8,
    label: 'Document Type',
    value: 'category',
    kendraType: '_category',
    filterType: 'checkbox'
  },
  {
    id: 9,
    label: 'Monoclonal Antibody',
    value: 'monoclonal_antibody',
    kendraType: 'doc-id-string-class-4',
    filterType: 'checkbox'
  },
  {
    id: 10,
    label: 'Approval Year',
    value: 'approval_date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  },
  {
    id: 11,
    label: 'Application Type',
    value: 'application_type',
    filterType: 'checkbox',
    kendraType: 'group-id-list-class-1'
  },
  {
    id: 12,
    label: 'Label Type',
    value: 'label_type',
    kendraType: 'group-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 17,
    label: 'Accelerated Approval',
    value: 'accelerated_approval',
    kendraType: 'doc-id-string-class-5',
    filterType: 'checkbox'
  },
  {
    id: 18,
    label: 'Breakthrough',
    value: 'bt_status',
    kendraType: 'doc-id-string-class-6',
    filterType: 'checkbox'
  },
  {
    id: 19,
    label: 'Fast Track',
    value: 'fast_track',
    kendraType: 'doc-id-string-class-7',
    filterType: 'checkbox'
  },
  {
    id: 20,
    label: 'RTOR',
    value: 'rtor',
    kendraType: 'doc-id-string-class-8',
    filterType: 'checkbox'
  },
  {
    id: 21,
    label: 'Orphan Status',
    value: 'orphan_status',
    kendraType: 'doc-id-string-class-9',
    filterType: 'checkbox'
  },
  {
    id: 22,
    label: 'Advisory Committee',
    value: 'advisory_committee',
    kendraType: 'doc-id-string-class-10',
    filterType: 'checkbox'
  },
  {
    id: 23,
    label: 'Combination Product',
    value: 'combination_product',
    kendraType: 'group-id-string-class-3',
    filterType: 'checkbox'
  }
];
const eu_filters = [
  {
    id: 'category_bucket',
    label: 'Category',
    value: 'category_bucket',
    kendraType: 'doc-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    kendraType: 'group-id-list-class-1',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'application_number',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Trade Name',
    value: 'trade_name',
    kendraType: 'group-id-list-class-9',
    filterType: 'checkbox'
  },
  {
    id: 8,
    label: 'Document Type',
    value: 'category',
    kendraType: '_category',
    filterType: 'checkbox'
  },
  {
    id: 9,
    label: 'Monoclonal Antibody',
    value: 'monoclonal-antibody',
    kendraType: 'doc-id-string-class-4',
    filterType: 'checkbox'
  },
  {
    id: 10,
    label: 'Biosimilar',
    value: 'biosimilar',
    kendraType: 'group-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 11,
    label: 'Conditional Approval',
    value: 'conditional_approval',
    kendraType: 'group-id-string-class-2',
    filterType: 'checkbox'
  },
  {
    id: 12,
    label: 'Exceptional Circumstances',
    value: 'exceptional_circumstances',
    kendraType: 'group-id-string-class-3',
    filterType: 'checkbox'
  },
  {
    id: 13,
    label: 'Accelerated Assessment',
    value: 'accelerated_assessment',
    kendraType: 'group-id-string-class-4',
    filterType: 'checkbox'
  },
  {
    id: 14,
    label: 'Orphan Medicine',
    value: 'orphan_medicine',
    kendraType: 'group-id-string-class-5',
    filterType: 'checkbox'
  },
  {
    id: 15,
    label: 'Advanced Therapy',
    value: 'advanced_therapy',
    kendraType: 'group-id-string-class-6',
    filterType: 'checkbox'
  },
  {
    id: 16,
    label: 'Combination Product',
    value: 'combination_product',
    kendraType: 'group-id-string-class-3',
    filterType: 'checkbox'
  },
  {
    id: 17,
    label: 'Approval Year',
    value: 'approval_date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  }
];

const devices_filters = [
  {
    id: 'category_bucket',
    label: 'Category',
    value: 'category_bucket',
    kendraType: 'doc-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Sponsor Name',
    value: 'sponsor',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Application Number',
    value: 'application_number',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Submission',
    value: 'submission',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Designation Type',
    value: 'designation_type',
    kendraType: 'group-id-list-class-1',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Document Type',
    value: 'category',
    kendraType: '_category',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Approval Year',
    value: 'approval_date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  }
];
const adcomm_filters = [
  {
    id: 'category_bucket',
    label: 'Category',
    value: 'category_bucket',
    kendraType: 'doc-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    kendraType: 'group-id-list-class-6',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    kendraType: 'group-id-list-class-7',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    kendraType: 'group-id-list-class-8',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor',
    kendraType: 'group-id-list-class-9',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'application_number',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Meeting Id',
    value: 'joint_meeting_ids',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Meeting End Date',
    value: 'adcom_meeting_end_date',
    kendraType: 'group-id-date-class-2',
    filterType: 'range'
  }
];
const guidance_filters = [
  {
    id: 1,
    label: 'Docket Number',
    value: 'field_docket_number',
    kendraType: 'group-id-list-class-1',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Topics',
    value: 'field_topics',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Open comment',
    value: 'open_comment',
    kendraType: 'doc-id-string-class-4',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Issue year',
    value: 'field_issue_datetime',
    kendraType: 'group-id-date-class-3',
    filterType: 'range'
  },
  {
    id: 5,
    label: 'FDA Organization',
    value: 'field_issuing_office_taxonomy',
    kendraType: 'group-id-list-class-8',
    filterType: 'checkbox'
  }
];

const uk_filters = [
  {
    id: 1,
    label: 'Document Category',
    value: 'document_category',
    kendraType: 'doc-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Reference Number',
    value: 'reference_number',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Guidance Programme',
    value: 'guidance_type',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Advice Programme',
    value: 'advice_programme',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Type',
    value: 'document_type',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Published',
    value: 'published',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  }
];

const usClinicalTrialsFilters = [
  {
    id: 1,
    label: 'NCTID',
    value: 'nct_id',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Conditions',
    value: 'conditions',
    kendraType: 'group-id-list-class-1',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Interventions',
    value: 'interventions',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Keywords',
    value: 'keywords',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Sponsors',
    value: 'sponsors',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Status',
    value: 'overall_status',
    kendraType: 'group-id-string-class-2',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Allocation',
    value: 'allocation',
    kendraType: 'group-id-string-class-4',
    filterType: 'checkbox'
  },
  {
    id: 8,
    label: 'Phase',
    value: 'phase',
    kendraType: 'group-id-string-class-5',
    filterType: 'checkbox'
  },
  {
    id: 9,
    label: 'Study Type',
    value: 'study_type',
    kendraType: 'group-id-string-class-6',
    filterType: 'checkbox'
  },
  {
    id: 10,
    label: 'Start Date',
    value: 'start_date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  },
  {
    id: 11,
    label: 'Completion Date',
    value: 'completion_date',
    kendraType: 'group-id-date-class-2',
    filterType: 'range'
  }
];

const emaPipsFilters = [
  {
    id: 'category_bucket',
    label: 'Category',
    value: 'category_bucket',
    kendraType: 'doc-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 1,
    label: 'Active Ingredients',
    value: 'active_ingredient',
    kendraType: 'group-id-list-class-1',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Formulations',
    value: 'formulation',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Trade Name',
    value: 'trade_name',
    kendraType: 'group-id-list-class-9',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Monoclonal Antibody',
    value: 'monoclonal-antibody',
    kendraType: 'group-id-string-class-4',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Therapeutic Area',
    value: 'therapeutic-area',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Decision Date',
    value: 'decision_date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  }
];

const emaGuidanceFilters = [
  {
    id: 1,
    label: 'EMA Document Number',
    value: 'ema_document_no',
    kendraType: 'group-id-list-class-1',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Status',
    value: 'status',
    kendraType: 'doc-id-string-class-2',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Document Type',
    value: 'document_type',
    kendraType: 'doc-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Last Published Date',
    value: 'last_published_date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  },
  {
    id: 5,
    label: 'Last Updated Date',
    value: 'last_updated_date',
    kendraType: 'group-id-date-class-2',
    filterType: 'range'
  },
  {
    id: 6,
    label: 'Legal Effective Date',
    value: 'legal_effective_date',
    kendraType: 'group-id-date-class-3',
    filterType: 'range'
  }
];

const fdaWrittenRequestFilters = [
  {
    id: 1,
    label: 'Drug Name',
    value: 'drug_name',
    kendraType: 'group-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Category',
    value: '_category',
    kendraType: '_category',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Written Request Sponsor',
    value: 'wr_sponsor',
    kendraType: 'group-id-string-class-2',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Determination Date',
    value: 'determination_date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  }
];

const chmpFilters = [
  {
    id: 1,
    label: 'Meeting Id',
    value: 'chmp-meeting-id',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Document Type',
    value: '_category',
    kendraType: '_category',
    filterType: 'checkbox'
  }
];
const canadaFilters = [
  {
    id: 1,
    label: 'Category',
    value: '_category',
    kendraType: '_category',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Active Ingredient',
    value: 'active-ingredient',
    kendraType: 'group-id-list-class-5',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Formulation',
    value: 'formulation',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Route',
    value: 'route',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Brand Name',
    value: 'brand-name',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Sponsor Name',
    value: 'sponsor',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Application Number',
    value: 'vin-number',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 8,
    label: 'Monoclonal Antibody',
    value: 'monoclonal-antibody',
    kendraType: 'group-id-list-class-8',
    filterType: 'checkbox'
  },
  {
    id: 10,
    label: 'NOC with conditon',
    value: 'noc-with-condition',
    kendraType: 'group-id-string-class-1',
    filterType: 'checkbox'
  },
  {
    id: 11,
    label: 'Priority Review',
    value: 'priority-review',
    kendraType: 'group-id-string-class-2',
    filterType: 'checkbox'
  },
  {
    id: 9,
    label: 'Submission Approval Date',
    value: 'submission-approval-date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  }
];

const hpraFilters = [
  {
    id: '_category',
    label: 'Category',
    value: '_category',
    kendraType: '_category',
    filterType: 'checkbox'
  },
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active-ingredient',
    kendraType: 'group-id-list-class-1',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Route',
    value: 'routes',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'product-number',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Approval Year',
    value: 'approval-date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  },
  {
    id: 7,
    label: 'Trade Name',
    value: 'trade-name',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 8,
    label: 'Center',
    value: 'data-source',
    kendraType: 'data-source',
    filterType: 'checkbox'
  },
  {
    id: 9,
    label: 'Marketing Status',
    value: 'marketing-status',
    kendraType: 'doc-id-string-class-5',
    filterType: 'checkbox'
  },
  {
    id: 10,
    label: 'Legal Status',
    value: 'legal-status',
    kendraType: 'doc-id-string-class-4',
    filterType: 'checkbox'
  },
  {
    id: 11,
    label: 'Licence Status',
    value: 'licence-status',
    kendraType: 'doc-id-string-class-6',
    filterType: 'checkbox'
  },
  {
    id: 12,
    label: 'Supply Status',
    value: 'supply-status',
    kendraType: 'doc-id-string-class-7',
    filterType: 'checkbox'
  },
  {
    id: 13,
    label: 'Advertising Status',
    value: 'advertising-status',
    kendraType: 'doc-id-string-class-8',
    filterType: 'checkbox'
  }
];
const PMDAFilters = [
  {
    id: 1,
    label: 'Category',
    value: '_category',
    kendraType: '_category',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Active Ingredient',
    value: 'active-ingredient',
    kendraType: 'group-id-list-class-1',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Formulation',
    value: 'formulation',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Route',
    value: 'route',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Trade Name',
    value: 'trade-name',
    kendraType: 'group-id-list-class-9',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Sponsor Name',
    value: 'sponsor',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 7,
    label: 'Application Number',
    value: 'yj_code',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 9,
    label: 'Approval Date',
    value: 'approval-date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  }
];

const canadaGuidanceFilters = [
  {
    id: 1,
    label: 'Title',
    value: 'title',
    kendraType: 'doc-id-string-class-3',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Keywords',
    value: 'keywords',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Last Updated Date',
    value: 'last_modified_date',
    kendraType: 'group-id-date-class-3',
    filterType: 'range'
  },
  {
    id: 4,
    label: 'Issue Date',
    value: 'issued_date',
    kendraType: 'group-id-date-class-2',
    filterType: 'range'
  }
];
const canadaConsultationFilters = [
  {
    id: 1,
    label: 'Title',
    value: 'title',
    kendraType: 'doc-id-string-class-3',
    filterType: 'checkbox'
  },
  {
    id: 2,
    label: 'Consultation Title',
    value: 'consultation_title',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Last Updated Date',
    value: 'last_modified_date',
    kendraType: 'group-id-date-class-3',
    filterType: 'range'
  },
  {
    id: 4,
    label: 'Issue Date',
    value: 'issued_date',
    kendraType: 'group-id-date-class-2',
    filterType: 'range'
  }
];

const hmaFilters = [
  {
    id: '_category',
    label: 'Category',
    value: 'category',
    kendraType: '_category',
    filterType: 'checkbox'
  },
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 10,
    label: 'Reference State',
    value: 'rm_state',
    kendraType: 'group-id-string-class-2',
    filterType: 'checkbox'
  },
  {
    id: 11,
    label: 'Concerned State',
    value: 'cm_state',
    kendraType: 'group-id-list-class-10',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'product_number',
    kendraType: 'group-id',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Approval Year',
    value: 'approval_date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  },
  {
    id: 7,
    label: 'Trade Name',
    value: 'trade_name',
    kendraType: 'group-id-list-class-9',
    filterType: 'checkbox'
  },
  {
    id: 9,
    label: 'Marketing Status',
    value: 'marketing_status',
    kendraType: 'group-id-string-class-3',
    filterType: 'checkbox'
  }
];

const fdaLettersFilters = [
  {
    id: 2,
    label: 'Source Page',
    value: 'source_page',
    kendraType: 'group-id-list-class-1',
    filterType: 'checkbox'
  },
  {
    id: 3,
    label: 'Company Name',
    value: 'company_name',
    kendraType: 'group-id-list-class-2',
    filterType: 'checkbox'
  },
  {
    id: 4,
    label: 'Document Type',
    value: 'document_type',
    kendraType: 'group-id-list-class-3',
    filterType: 'checkbox'
  },
  {
    id: 5,
    label: 'Issuing Office',
    value: 'issuing_office',
    kendraType: 'group-id-list-class-4',
    filterType: 'checkbox'
  },
  {
    id: 6,
    label: 'Subject',
    value: 'subject',
    kendraType: 'group-id-list-class-5',
    filterType: 'checkbox'
  },
  {
    id: 8,
    label: 'Issue Year',
    value: 'issue_date',
    kendraType: 'group-id-date-class-1',
    filterType: 'range'
  },
  {
    id: 9,
    label: 'Posted Year',
    value: 'posted_date',
    kendraType: 'group-id-date-class-2',
    filterType: 'range'
  }
];

export const us_eu_devices_keys = ['us', 'us-devices', 'eu'];

export const filterOptions = {
  us: us_filters,
  eu: eu_filters,
  'us-devices': devices_filters,
  guidance: guidance_filters,
  adcomm: adcomm_filters,
  uk: uk_filters,
  ct: usClinicalTrialsFilters,
  'ema-pips': emaPipsFilters,
  'ema-guidance': emaGuidanceFilters,
  'canada-guidance': canadaGuidanceFilters,
  'canada-consultation': canadaConsultationFilters,
  'fda-letters': fdaLettersFilters,
  'fda-written-request': fdaWrittenRequestFilters,
  ca: canadaFilters,
  chmp: chmpFilters,
  hpra: hpraFilters,
  hma: hmaFilters,
  jp: PMDAFilters
};

export const scoreConfidenceStyles = {
  VERY_HIGH: 'veryHighIcon',
  HIGH: 'highIcon',
  MEDIUM: 'mediumIcon',
  LOW: 'lowIcon',
  NOT_AVAILABLE: 'noConfidence'
};

const us_eu_devices_aria_card_mapping = {
  title: 'active_ingredient',
  displayName: 'Active Ingredient',
  description: 'text',
  date: {
    type: 'approval_date',
    displayName: 'Approval Date'
  },
  metadataOne: {
    type: 'application_number',
    displayName: 'Application Number'
  },
  metadataTwo: {
    type: 'submission_label',
    displayName: 'Submission'
  },
  metadataThree: {
    type: 'category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const chmpCardMapping = {
  title: 'chmp-meeting-id',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'first-published',
    displayName: 'First Published'
  },
  date1: {
    type: 'last-updated',
    displayName: 'Last Updated'
  },
  metadataOne: {
    type: 'meeting-year',
    displayName: 'Meeting Info'
  },
  metadataTwo: {
    type: 'category-buckets',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const canadaCardMapping = {
  title: 'active-ingredient',
  displayName: 'Active Ingredient',
  description: 'text',
  date: {
    type: 'submission-approval-date',
    displayName: 'Submission Approval Date'
  },
  metadataOne: {
    type: 'vin-number',
    displayName: 'Application Number'
  },
  metadataTwo: {
    type: 'brand-name',
    displayName: 'Brand Name'
  },
  metadataThree: {
    type: '_category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const adcomm_aria_card_mapping = {
  title: 'active_ingredient',
  displayName: 'Active Ingredient',
  description: 'text',
  date: {
    type: 'adcom_meeting_start_date',
    displayName: 'AdComm Meeting Start Date'
  },
  metadataOne: {
    type: 'application_number',
    displayName: 'Application Number'
  },
  metadataTwo: {
    type: 'meeting_id',
    displayName: 'Meeting ID'
  },
  metadataThree: {
    type: 'category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};
const adcomm_cber_aria_card_mapping = {
  title: 'document-title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'adcom_meeting_start_date',
    displayName: 'AdComm Meeting Start Date'
  },
  metadataOne: {
    type: 'application_number',
    displayName: 'Application Number'
  },
  metadataTwo: {
    type: 'meeting_id',
    displayName: 'Meeting ID'
  },
  metadataThree: {
    type: 'category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const adcomm_cdrh_aria_card_mapping = {
  title: 'device_name',
  displayName: 'Device Name',
  description: 'text',
  date: {
    type: 'adcom_meeting_start_date',
    displayName: 'AdComm Meeting Start Date'
  },
  metadataOne: {
    type: 'sponsor',
    displayName: 'Sponsor'
  },
  metadataTwo: {
    type: 'meeting_id',
    displayName: 'Meeting ID'
  },
  metadataThree: {
    type: 'category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const guidance_aria_card_mapping = {
  title: 'title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'field_issue_datetime',
    displayName: 'Issue Datetime'
  },
  metadataOne: {
    type: 'field_docket_number',
    displayName: 'Docket Number'
  },
  metadataTwo: {
    type: 'field_regulated_product_field',
    displayName: 'Regulated Project Field'
  },
  metadataThree: {
    type: 'field_center',
    displayName: 'Center'
  }
};

const ema_guidance_aria_card_mapping = {
  title: 'doc_title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'last_published_date',
    displayName: 'Published Date'
  },
  metadataOne: {
    type: 'ema_document_no',
    displayName: 'EMA Document Number'
  },
  metadataTwo: {
    type: 'status',
    displayName: 'Status'
  },
  metadataThree: {
    type: 'document_type',
    displayName: 'Document Type'
  }
};

const fda_written_request_aria_card_mapping = {
  title: 'title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'determination_date',
    displayName: 'Determination Date'
  },
  metadataOne: {
    type: 'drug_name',
    displayName: 'Drug Name'
  },
  metadataTwo: {
    type: 'wr_sponsor',
    displayName: 'Written Request Sponsor'
  },
  metadataThree: {
    type: '_category',
    displayName: 'Category'
  }
};

const uk_aria_card_mapping = {
  title: 'title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'published',
    displayName: 'Published Datetime'
  },
  metadataOne: {
    type: 'reference_number',
    displayName: 'Reference Number'
  },
  metadataTwo: {
    type: 'document_type',
    displayName: 'Document Type'
  },
  metadataThree: {
    type: 'document_category',
    displayName: 'Document Category'
  }
};

const clinicalTrialsAriaCardMapping = {
  title: 'brief_title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'start_date',
    displayName: 'Start Date'
  },
  metadataOne: {
    type: 'nct_id',
    displayName: 'NCTID'
  },
  metadataTwo: {
    type: 'phase',
    displayName: 'Phase'
  },
  metadataThree: {
    type: 'category_bucket',
    displayName: 'Document Type'
  }
};

const euCtrAriaCardMapping = {
  title: 'brief_title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'start_date',
    displayName: 'Start Date'
  },
  metadataOne: {
    type: 'euct_id',
    displayName: 'EUCT ID'
  },
  metadataTwo: {
    type: 'phase',
    displayName: 'Phase'
  },
  metadataThree: {
    type: 'category',
    displayName: 'Document Type'
  }
};

const auTgaCardMapping = {
  title: 'active_ingredients',
  displayName: 'Active Ingredient',
  description: 'text',
  date: {
    type: 'start_date',
    displayName: 'Start Date'
  },
  metadataOne: {
    type: 'vin',
    displayName: 'Vin Number'
  },
  metadataTwo: {
    type: 'category_bucket',
    displayName: 'Category'
  }
};
const emaPipsAriaCardMapping = {
  title: 'active_ingredient',
  displayName: 'Active Ingredient',
  description: 'text',
  date: {
    type: 'decision_date',
    displayName: 'Decision Date'
  },
  metadataOne: {
    type: 'decision-number',
    displayName: 'Decision Number'
  },
  metadataTwo: {
    type: 'formulation',
    displayName: 'Formulation'
  },
  metadataThree: {
    type: 'route',
    displayName: 'Route'
  },
  scoreConfidence: 'score_confidence'
};

const hpraAriaCardMapping = {
  title: 'active-ingredient',
  displayName: 'Active Ingredient',
  description: 'text',
  date: {
    type: 'approval-date',
    displayName: 'Approval Date'
  },
  metadataOne: {
    type: 'product-number',
    displayName: 'Product Number'
  },
  metadataTwo: {
    type: 'sponsor',
    displayName: 'Sponsor'
  },
  metadataThree: {
    type: '_category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const hmaAriaCardMapping = {
  title: 'active_ingredient',
  displayName: 'Active Ingredient',
  description: 'text',
  date: {
    type: 'approval_date',
    displayName: 'Approval Date'
  },
  metadataOne: {
    type: 'product_number',
    displayName: 'Product Number'
  },
  metadataTwo: {
    type: 'sponsor',
    displayName: 'Sponsor'
  },
  metadataThree: {
    type: 'category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const canadaConsultationCardMapping = {
  title: 'title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'issued_date',
    displayName: 'Issue Date'
  },
  date1: {
    type: 'end_date',
    displayName: 'End Date'
  },
  metadataOne: {
    type: 'document_type',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const canadaGuidanceCardMapping = {
  title: 'title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'issued_date',
    displayName: 'Issue Date'
  },
  date1: {
    type: 'last_modified_date',
    displayName: 'Last Updated Date'
  },
  metadataOne: {
    type: '_category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const fdaLettersCardMapping = {
  title: 'title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'issue_date',
    displayName: 'Issue Date'
  },
  metadataOne: {
    type: '_category',
    displayName: 'Category'
  },
  metadataTwo: {
    type: 'company_name',
    displayName: 'Sponsor'
  },
  scoreConfidence: 'score_confidence'
};

const secEdgarCardMapping = {
  title: 'companies',
  displayName: 'Sponsors',
  description: 'text',
  date: {
    type: 'filing_date',
    displayName: 'Filing Date'
  },
  metadataOne: {
    type: '_category',
    displayName: 'Category'
  },
  metadataTwo: {
    type: 'ciks',
    displayName: 'CIK'
  },
  scoreConfidence: 'score_confidence'
};

const PMDACardMapping = {
  title: 'active-ingredient',
  displayName: 'Active Ingredient',
  description: 'text',
  date: {
    type: 'approval-date',
    displayName: 'Approval Date'
  },
  metadataOne: {
    type: 'yj_code',
    displayName: 'Application Number'
  },
  metadataTwo: {
    type: 'trade-name',
    displayName: 'Trade Name'
  },
  metadataThree: {
    type: '_category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const drugDevelopmentToolsCardMapping = {
  title: 'title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'stage_date',
    displayName: 'Stage Date'
  },
  metadataOne: {
    type: 'project_number',
    displayName: 'Project Number'
  },
  metadataTwo: {
    type: '_category',
    displayName: 'Stage'
  },
  metadataThree: {
    type: 'category_bucket',
    displayName: 'Document Type'
  },
  scoreConfidence: 'score_confidence'
};

const emaOrphanDesignationsCardMapping = {
  title: 'document-title',
  displayName: 'Title',
  description: 'text',
  date: {
    type: 'approval-date',
    displayName: 'Approval Date'
  },
  metadataOne: {
    type: 'document-info',
    displayName: 'Procedure Type/Status'
  },
  metadataTwo: {
    type: 'category-bucket',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const pdcoCardMapping = {
  title: 'original-file-name',
  displayName: 'Title',
  description: 'text',
  metadataOne: {
    type: 'meeting-year',
    displayName: 'Meeting Info'
  },
  metadataTwo: {
    type: 'category-bucket',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const CDECardMapping = {
  title: 'generic_name',
  displayName: 'Active Ingredient',
  description: 'text',
  date: {
    type: 'publication_date',
    displayName: 'Publication Date'
  },
  metadataOne: {
    type: 'vin',
    displayName: 'Application Number'
  },
  metadataTwo: {
    type: 'trade_name',
    displayName: 'Trade Name'
  },
  metadataThree: {
    type: 'category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

const mhraCardMapping = {
  title: 'active_ingredient',
  displayName: 'Active Ingredient',
  description: 'text',
  date: {
    type: 'approval_date',
    displayName: 'Approval Date'
  },
  metadataOne: {
    type: 'vin',
    displayName: 'Vin Number'
  },
  metadataTwo: {
    type: '_category',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};
const pracCardMapping = {
  title: 'original-file-name',
  displayName: 'Title',
  description: 'text',
  metadataOne: {
    type: 'meeting-year',
    displayName: 'Meeting Info'
  },
  metadataTwo: {
    type: 'category-bucket',
    displayName: 'Category'
  },
  scoreConfidence: 'score_confidence'
};

export const ariaCardResultsMapping = {
  ...Object.assign(...us_eu_devices_keys.map(k => ({ [k]: us_eu_devices_aria_card_mapping }))),
  guidance: guidance_aria_card_mapping,
  adcomm: adcomm_aria_card_mapping,
  'adcomm-cdrh': adcomm_cdrh_aria_card_mapping,
  'adcomm-cber': adcomm_cber_aria_card_mapping,
  uk: uk_aria_card_mapping,
  ct: clinicalTrialsAriaCardMapping,
  'ema-pips': emaPipsAriaCardMapping,
  'ema-guidance': ema_guidance_aria_card_mapping,
  'canada-guidance': canadaGuidanceCardMapping,
  'canada-consultation': canadaConsultationCardMapping,
  'fda-letters': fdaLettersCardMapping,
  'fda-written-request': fda_written_request_aria_card_mapping,
  ca: canadaCardMapping,
  chmp: chmpCardMapping,
  hpra: hpraAriaCardMapping,
  hma: hmaAriaCardMapping,
  jp: PMDACardMapping,
  'dd-tools': drugDevelopmentToolsCardMapping,
  euctr: euCtrAriaCardMapping,
  ema_orphan: emaOrphanDesignationsCardMapping,
  pdco: pdcoCardMapping,
  'sec-edgar': secEdgarCardMapping,
  au: auTgaCardMapping,
  cde: CDECardMapping,
  mhra: mhraCardMapping,
  prac: pracCardMapping,
  chn: CDECardMapping
};

export const TAG_KEYS = {
  ...Object.assign(
    ...[
      'us',
      'guidance',
      'ca',
      'us-devices',
      'adcomm',
      'adcomm-cdrh',
      'adcomm-cber',
      'ema-guidance',
      'fda-written-request',
      'jp',
      'dd-tools',
      'cde'
    ].map(k => ({
      [k]: ['date', 'metadataOne', 'metadataTwo', 'metadataThree']
    }))
  ),
  eu: ['date', 'metadataOne', 'metadataThree'],
  'canada-guidance': ['date', 'date1', 'metadataOne'],
  'canada-consultation': ['date', 'date1', 'metadataOne'],
  'fda-letters': ['date', 'metadataOne', 'metadataTwo'],
  uk: ['date', 'metadataOne', 'metadataTwo', 'metadataThree'],
  ct: ['date', 'metadataOne', 'metadataTwo', 'metadataThree'],
  euctr: ['date', 'metadataOne', 'metadataTwo', 'metadataThree'],
  'ema-pips': ['date', 'metadataOne', 'metadataTwo', 'metadataThree'],
  hpra: ['date', 'metadataOne', 'metadataTwo', 'metadataThree'],
  hma: ['date', 'metadataOne', 'metadataTwo', 'metadataThree'],
  chmp: ['date', 'date1', 'metadataOne', 'metadataTwo'],
  ema_orphan: ['date', 'metadataOne', 'metadataTwo'],
  au: ['date', 'metadataOne', 'metadataTwo'],
  pdco: ['metadataOne', 'metadataTwo'],
  mhra: ['date', 'metadataOne', 'metadataTwo'],
  prac: ['metadataOne', 'metadataTwo'],
  'sec-edgar': ['date', 'metadataOne', 'metadataTwo'],
  chn: ['date', 'metadataOne', 'metadataTwo', 'metadataThree']
};

export const ERROR_MESSAGES = {
  404: "We didn't find any results that matched your search query. Please try again with different keywords.",
  500: 'Something went wrong. Please try again',
  429: 'Too many requests'
};

// used for back button on ARIA
export const MODULE_NAME_MAPPING_NAVIGATION = {
  bla: 'biologics',
  advisoryCommittee: 'adcomm',
  core: 'core',
  '505b2': '505b2',
  guidanceDocuments: 'guidanceDocuments',
  clinicalTrials: 'core',
  chmp: 'Chmp'
};

export const DOCUMENT_STATE = {
  open: 'OPEN',
  loading: 'LOADING',
  close: 'CLOSE'
};

// sources that will have subtitle
export const SOURCE_WITH_SUBTITLE = ['us', 'eu', 'ema-pips', 'hpra', 'hma', 'cde'];

// sources with reg360
export const SOURCE_WITH_REG360 = [
  'us',
  'eu',
  'ca',
  'ct',
  'us-devices',
  'hpra',
  'hma',
  'jp',
  'mhra',
  'chn'
];

// page results
export const PAGE_NUMBER_RANGES = {
  1: '1 - 100',
  2: '101 - 200',
  3: '201 - 300',
  4: '301 - 400',
  5: '401 - 500',
  6: '501 - 600',
  7: '601 - 700',
  8: '701 - 800',
  9: '801 - 900',
  10: '901 - 1000'
};

export const UNIQUE_FIELD_MAPPING = {
  us: 'application_number',
  eu: 'application_number',
  ct: 'nct_id',
  ca: 'vin-number',
  'us-devices': 'application_number',
  hpra: 'product-number',
  hma: 'product_number',
  jp: 'yj_code',
  chn: 'vin',
  mhra: 'vin'
};

const usFrontEndAriaFilters = [
  {
    id: 'category_bucket',
    label: 'Category'
  },
  {
    id: 'application_type',
    label: 'Application Type'
  },
  {
    id: 'sponsor',
    label: 'Sponsor Name'
  },
  {
    id: 'trade_name',
    label: 'Trade Name'
  }
];

const euFrontEndAriaFilters = [
  {
    id: 'category_bucket',
    label: 'Category'
  },
  {
    id: 'category',
    label: 'Document Type'
  },
  {
    id: 'sponsor',
    label: 'Sponsor Name'
  },
  {
    id: 'trade_name',
    label: 'Trade Name'
  }
];

const devicesFrontEndAriaFilters = [
  {
    id: 'category_bucket',
    label: 'Category'
  },
  {
    id: 'designation_type',
    label: 'Designation Type'
  },
  {
    id: 'category',
    label: 'Document Type'
  },
  {
    id: 'sponsor',
    label: 'Sponsor Name'
  },
  {
    id: 'trade_name',
    label: 'Trade Name'
  }
];

const adcommFrontEndAriaFilters = [
  {
    id: 'category_bucket',
    label: 'Category'
  },
  {
    id: 'sponsor',
    label: 'Sponsor Name'
  }
];

const guidanceFrontEndAriaFilters = [
  {
    id: 'field_topics',
    label: 'Topics'
  },
  {
    id: 'open_comment',
    label: 'Open comment'
  },
  {
    id: 'field_issuing_office_taxonomy',
    label: 'FDA Organization'
  }
];

const ukFrontEndAriaFilters = [
  {
    id: 'document_category',
    label: 'Document Category'
  },
  {
    id: 'document_type',
    label: 'Type'
  }
];

const usClinicalTrialsFrontEndAriaFilters = [
  {
    id: 'overall_status',
    label: 'Status'
  },
  {
    id: 'allocation',
    label: 'Allocation'
  },
  {
    id: 'phase',
    label: 'Phase'
  },
  {
    id: 'study_type',
    label: 'Study Type'
  },
  {
    id: 'sponsors',
    label: 'Sponsors'
  }
];

const euClinicalTrialsFrontEndAriaFilters = [
  {
    id: 'allocation',
    label: 'Allocation'
  },
  {
    id: 'phase',
    label: 'Phase'
  },
  {
    id: 'interventions',
    label: 'Interventions'
  },
  {
    id: 'sponsors',
    label: 'Sponsors'
  },
  {
    id: 'category',
    label: 'Document Type'
  },
  {
    id: 'conditions',
    label: 'Conditions'
  },
  {
    id: 'enrollment',
    label: 'Enrollment'
  }
];

const auFrontEndAriaFilters = [
  {
    id: 'category_bucket',
    label: 'Category'
  },
  {
    id: 'sponsor_name',
    label: 'Sponsor Name'
  },
  {
    id: 'formulation',
    label: 'Formulation'
  },
  {
    id: 'route',
    label: 'Route'
  },
  {
    id: 'product_name',
    label: 'Trade Name'
  }
];

const emaPipsFrontEndAriaFilters = [
  {
    id: 'category_bucket',
    label: 'Category'
  },
  {
    id: 'trade_name',
    label: 'Trade Name'
  }
];

const emaGuidanceFrontEndAriaFilters = [
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'document_type',
    label: 'Document Type'
  }
];

const fdaWrittenRequestFrontEndAriaFilters = [
  {
    id: '_category',
    label: 'Category'
  },
  {
    id: 'wr_sponsor',
    label: 'Written Request Sponsor'
  }
];

const canadaFrontEndAriaFilters = [
  {
    id: '_category',
    label: 'Category'
  },
  {
    id: 'sponsor',
    label: 'Sponsor Name'
  }
];

const chmpFrontEndAriaFilters = [
  {
    id: 'chmp-meeting-id',
    label: 'Meeting Id'
  },
  {
    id: '_category',
    label: 'Document Type'
  }
];

const hpraFrontEndAriaFilters = [
  {
    id: '_category',
    label: 'Document Type'
  },
  {
    id: 'sponsor',
    label: 'Sponsor Name'
  },
  {
    id: 'trade-name',
    label: 'Trade Name'
  }
];

const hmaFrontEndAriaFilters = [
  {
    id: 'category',
    label: 'Document Type'
  },
  {
    id: 'sponsor',
    label: 'Sponsor Name'
  },
  {
    id: 'trade_name',
    label: 'Trade Name'
  }
];

const canadaGuidanceFrontEndAriaFilters = [
  {
    id: 'keywords',
    label: 'Keywords'
  },
  {
    id: '_category',
    label: 'Document Type'
  }
];
const canadaConsultationFrontEndAriaFilters = [
  {
    id: 'document_type',
    label: 'Document Type'
  },
  {
    id: 'subjects',
    label: 'Subjects'
  }
];

const PMDAFrontEndAriaFilters = [
  {
    id: '_category',
    label: 'Category'
  },
  {
    id: 'sponsor',
    label: 'Sponsor Name'
  }
];

const ddToolsFrontEndAriaFilters = [
  {
    id: 'category_bucket',
    label: 'Document Type'
  },
  {
    id: '_category',
    label: 'Stage'
  }
];

const emaOrphanDesignationsFrontEndAriaFilters = [
  {
    id: 'category',
    label: 'Document Type'
  },
  {
    id: 'category-bucket',
    label: 'Category'
  }
];
const pdcoFrontEndAriaFilters = [
  {
    id: 'meeting-year',
    label: 'Meeting Date'
  },
  {
    id: '_category',
    label: 'Category'
  }
];

const pracFrontEndAriaFilters = [
  {
    id: 'meeting-year',
    label: 'Meeting Date'
  },
  {
    id: '_category',
    label: 'Category'
  }
];

const secEdgarFrontEndAriaFilters = [
  {
    id: 'companies',
    label: 'Sponsors'
  },
  {
    id: '_category',
    label: 'Category'
  },
  {
    id: 'ciks',
    label: 'CIK'
  },
  {
    id: 'file_number',
    label: 'File Number'
  }
];

// eslint-disable-next-line no-unused-vars
const fdaLettersFrontEndAriaFilters = [
  {
    id: 'source_page',
    label: 'Source Page'
  },
  {
    id: 'company_name',
    label: ' Company Name'
  },
  {
    id: 'document_type',
    label: ' Document Type'
  },
  {
    id: 'subject',
    label: ' Subject'
  },
  {
    id: 'issuing_office',
    label: 'Issuing Office'
  }
];

const CDEFrontEndAriaFilters = [
  {
    id: '_category',
    label: 'Category'
  },
  {
    id: 'sponsor',
    label: 'Sponsor Name'
  }
];

const mhraFrontEndAriaFilters = [
  {
    id: '_category',
    label: 'Category'
  },
  {
    id: 'sponsor_name',
    label: 'Sponsor Name'
  },
  {
    id: 'formulation',
    label: 'Formulation'
  },
  {
    id: 'route',
    label: 'Route'
  },
  {
    id: 'product_name',
    label: 'Trade Name'
  }
];

export const frontEndAriaFilters = [
  { id: 'documentTitle', label: 'Document Title' },
  { id: 'documentCategory', label: 'Document Category' }
];

export const CHAT_RIA_DEFAULT_TEXT =
  'Sorry, we were unable to generate a response for your query based on the first document. You can explore the next best relevant documents for a generative AI response or consider trying one of the suggested questions below.';

export const frontEndAriaFiltersBySource = {
  us: usFrontEndAriaFilters,
  eu: euFrontEndAriaFilters,
  epar: euFrontEndAriaFilters,
  'us-devices': devicesFrontEndAriaFilters,
  devices: devicesFrontEndAriaFilters,
  guidance: guidanceFrontEndAriaFilters,
  adcomm: adcommFrontEndAriaFilters,
  uk: ukFrontEndAriaFilters,
  ct: usClinicalTrialsFrontEndAriaFilters,
  euctr: euClinicalTrialsFrontEndAriaFilters,
  'ema-pips': emaPipsFrontEndAriaFilters,
  'ema-guidance': emaGuidanceFrontEndAriaFilters,
  'canada-guidance': canadaGuidanceFrontEndAriaFilters,
  'canada-consultation': canadaConsultationFrontEndAriaFilters,
  'fda-written-request': fdaWrittenRequestFrontEndAriaFilters,
  ca: canadaFrontEndAriaFilters,
  chmp: chmpFrontEndAriaFilters,
  hpra: hpraFrontEndAriaFilters,
  hma: hmaFrontEndAriaFilters,
  jp: PMDAFrontEndAriaFilters,
  ie: hpraFrontEndAriaFilters,
  'dd-tools': ddToolsFrontEndAriaFilters,
  ema_orphan: emaOrphanDesignationsFrontEndAriaFilters,
  pdco: pdcoFrontEndAriaFilters,
  sec: secEdgarFrontEndAriaFilters,
  au: auFrontEndAriaFilters,
  cde: CDEFrontEndAriaFilters,
  mhra: mhraFrontEndAriaFilters,
  prac: pracFrontEndAriaFilters,
  chn: CDEFrontEndAriaFilters
};
