import { createSvgIcon } from '@mui/material';

const ChinaFlagIcon = createSvgIcon(
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M18 0H0V12H18V0Z' fill='#D80027' />
    <path
      d='M3.38291 1.80371L3.89816 3.38949H5.56558L4.2166 4.36956L4.73189 5.95541L3.38291 4.9753L2.03393 5.95541L2.54918 4.36956L1.2002 3.38949H2.86762L3.38291 1.80371Z'
      fill='#FFDA44'
    />
    <path
      d='M6.63105 7.07742L6.12515 6.70987L5.61932 7.07739L5.8125 6.48276L5.30664 6.1152H5.93193L6.12515 5.52051L6.31844 6.1152H6.94369L6.43783 6.48272L6.63105 7.07742Z'
      fill='#FFDA44'
    />
    <path
      d='M7.94952 5.26104H7.32426L7.13104 5.85567L6.93782 5.26108L6.3125 5.26104L6.81843 4.89349L6.62514 4.29883L7.13104 4.66631L7.63691 4.29883L7.44369 4.89349L7.94952 5.26104Z'
      fill='#FFDA44'
    />
    <path
      d='M7.94952 3.01657L7.44369 3.38413L7.63687 3.97875L7.13104 3.61127L6.62514 3.97882L6.81843 3.38409L6.3125 3.01664L6.93786 3.01657L7.13104 2.42188L7.32426 3.01657H7.94952Z'
      fill='#FFDA44'
    />
    <path
      d='M6.63101 1.20023L6.43783 1.79493L6.94366 2.16241L6.3184 2.16245L6.12511 2.75718L5.93189 2.16241L5.30664 2.16248L5.8125 1.79489L5.61929 1.2002L6.12511 1.56775L6.63101 1.20023Z'
      fill='#FFDA44'
    />
  </svg>,
  'ChinaFlag'
);

export default ChinaFlagIcon;
