const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 2,
      backgroundColor: 'white.main',
      width: 833,
      maxWidth: 833,
      height: 748,
      maxHeight: 748,
      px: 3,
      pt: 3,
      pb: 5
    },
    '& .MuiDialog-paperFullScreen': {
      backgroundColor: 'transparent'
    }
  },
  closeButtonWrapper: {
    position: 'absolute',
    right: '2%'
  },
  title: {
    fontFamily: 'Mulish',
    color: 'black.main',
    fontSize: 20,
    fontWeight: 700
  },
  closeIconButton: { '& svg': { height: 20, width: 20 } },
  divider: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: 2,
    mt: 0.75,
    width: 64
  },
  resetButton: {
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'primary.700',
    color: 'primary.700',
    textTransform: 'none',
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 600,
    px: 2,
    py: 1,
    height: 32,
    width: 120,
    '&:hover': {
      backgroundColor: 'primary.0'
    },
    '&:active': {
      backgroundColor: 'primary.50'
    },
    '&:disabled': {
      borderWidth: 0,
      backgroundColor: 'gray.200',
      color: 'gray.500'
    }
  },
  applyButton: {
    px: 2,
    py: 1,
    height: 32,
    width: 120,
    fontSize: 14,
    borderRadius: 6,
    backgroundColor: 'primary.600',
    color: 'white.main',
    textTransform: 'none',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'primary.700'
    },
    '&:disabled': {
      backgroundColor: 'gray.200',
      color: 'gray.500'
    },
    '&:active': {
      backgroundColor: 'primary.900'
    }
  },
  sourceContainer: {
    borderRadius: 3,
    backgroundColor: 'gray.50',
    py: 1
  },
  parentContainer: {
    cursor: 'pointer',
    mx: 1.875,
    '&:hover': {
      backgroundColor: 'gray.100'
    }
  },
  childrenContainer: {
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: 'gray.300',
    px: 1.125,
    ml: 4,
    my: 0.75
  },
  childCheckboxContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: 32,
    '&:hover': {
      backgroundColor: 'gray.100'
    }
  },
  checkbox: {
    '&.Mui-checked': {
      color: 'primary.600'
    }
  },
  childLabelText: {
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: 400,
    color: 'gray.800'
  },
  parentLabelText: {
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: 700,
    color: 'gray.700'
  },
  sourceCheckbox: {
    usfda: { height: 304 },
    euema: { height: 284 },
    eu_nmr: { height: 124 },
    ca: { height: 212 },
    jp: { height: 92 },
    ct: { height: 132 },
    au: { height: 92 },
    sec: { height: 92 },
    uk: { height: 92 },
    chn: { height: 92 }
  }
};

export default styles;
