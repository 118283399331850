import React, { useCallback, useEffect, useState } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {
  Box,
  Grid,
  Typography,
  Drawer,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  Tooltip,
  Button
} from '@mui/material';
import useLabels from './hooks/useLabels';
import { getLabelComparision, getSignedLabelURL } from '../../api/pages/Reg360';
import DraftableComparisonView from './DraftableComparisonView';
import getComparisonFile from '../../helpers/draftableUtils';
import { LeftArrowIcon } from '../../assets/svgs/Icons';

import LabelList from './LabelList';
import comparisonDialogStyles from './styles/ComparisonDialog.styles';
import ReportingDrawer from '../Report/ReportingDrawer';

export interface ApplicationProps {
  source: string;
  application_type?: string;
  application_number?: string;
  product_number?: string;
  vin?: string;
  application_name?: string;
  yj_code?: string;
  labels?: Array<any>;
}

export interface ModeOfComparisonDialog {
  child_labelList_dependent_on_parent: boolean;
  section_binding: boolean;
  allsections: boolean;
  default_section_all_section: boolean;
  parentRld?: boolean;
  childRld?: boolean;
  sortOnDate?: boolean;
}

interface ComparisonDialogProps {
  open: boolean;
  onClose: () => void;
  applications: Array<ApplicationProps>;
  mode: ModeOfComparisonDialog;
  showBackButton?: boolean;
  onBack?: () => void;
}

interface SelectedSectionProps extends ApplicationProps {
  section: string;
  pdfLink: string;
  submissionType: string;
  submissionNum: string;
  approvalDate: string;
  description: string;
  applicationLabels?: Array<any>;
  center?: string;
  sourceType?: string;
}

const ComparisonDialog: React.FC<ComparisonDialogProps> = ({
  open,
  onClose,
  applications = [],
  mode,
  showBackButton = false,
  onBack = undefined
}) => {
  const [selectedApplications, setSelectedApplications]: [
    Array<ApplicationProps>,
    // eslint-disable-next-line no-unused-vars
    (appls: Array<ApplicationProps>) => void
  ] = useState([] as Array<ApplicationProps>);
  const [labelOrder, setLabelOrder] = useState(['parent', 'child']);
  const [showSummary, setShowSummary] = useState(false);
  const [switchTriggered, setSwitchTriggered] = useState(false);
  const [parentLabelSection, setParentLabelSection]: [
    SelectedSectionProps | undefined,
    // eslint-disable-next-line no-unused-vars
    (section: SelectedSectionProps | undefined) => void
  ] = useState();

  const [childLabelSection, setChildLabelSection]: [
    SelectedSectionProps | undefined,
    // eslint-disable-next-line no-unused-vars
    (section: SelectedSectionProps | undefined) => void
  ] = useState();

  // eslint-disable-next-line no-unused-vars
  const [viewURL, setViewerURL]: [string | undefined, (url: string | undefined) => void] =
    useState();

  const [oldLabelSection, setOldLabelSection]: [
    SelectedSectionProps | undefined,
    // eslint-disable-next-line no-unused-vars
    (section: SelectedSectionProps | undefined) => void
  ] = useState();

  const [newLabelSection, setNewLabelSection]: [
    SelectedSectionProps | undefined,
    // eslint-disable-next-line no-unused-vars
    (section: SelectedSectionProps | undefined) => void
  ] = useState();

  const [childLabels, setChildLabels] = useState();
  const [openReporting, setOpenReporting] = React.useState<boolean>(false);

  const {
    isLoading: parentLabelLoading,
    error: parentLabelLoadingError,
    labels: parentLabelList,
    getLabels: getParentLabels
  } = useLabels(mode.allsections);

  let {
    isLoading: childLabelLoading,
    error: childLabelLoadingError,
    labels: childLabelList,
    getLabels: getChildLabels
  } = useLabels(mode.allsections);

  if (mode.child_labelList_dependent_on_parent) {
    // if the child label list dependent on parent ;at first it should be the same,
    // so no need to call the api for getting the labellist
    childLabelLoading = parentLabelLoading;
    childLabelLoadingError = parentLabelLoadingError;
    childLabelList = parentLabelList;
    getChildLabels = getParentLabels;
  }

  const handleSignedURL = useCallback(async (payload: any) => {
    const res: any = await getSignedLabelURL({
      source: payload?.source?.toLowerCase() === 'eu' ? payload?.sourceType : payload?.source,
      s3_path: payload?.pdfLink,
      center: payload?.center,
      is_section: payload?.section !== 'all_sections'
    });
    return res?.data?.body?.s3_url ?? '';
  }, []);

  useEffect(() => {
    const rldCompare = async () => {
      const oldSection: SelectedSectionProps = {
        ...applications[0],
        section: '',
        pdfLink: '',
        submissionNum: '',
        submissionType: '',
        approvalDate: '',
        description: ''
      };
      const newSection: SelectedSectionProps = {
        ...applications[1],
        section: '',
        pdfLink: '',
        submissionNum: '',
        submissionType: '',
        approvalDate: '',
        description: ''
      };
      const res = await getLabelComparision({
        parent_object: `${oldSection?.application_type?.toUpperCase()}${
          oldSection?.application_number
        }`,
        child_object: `${newSection?.application_type?.toUpperCase()}${
          newSection?.application_number
        }`
      });

      setOldLabelSection({ ...oldSection });
      setNewLabelSection({ ...newSection });

      if (res.data.success !== null) {
        setViewerURL(res.data.success);
      }
    };

    setSelectedApplications(applications);
    if (mode.parentRld && mode.childRld) {
      rldCompare();
      return;
    }
    if (applications.length === 2) {
      getParentLabels(
        applications[0].source,
        (applications?.[0]?.application_number ||
          applications?.[0]?.product_number ||
          applications[0]?.vin ||
          applications?.[0]?.yj_code) as string
      );
      if (!mode.child_labelList_dependent_on_parent) {
        getChildLabels(
          applications[1].source,
          (applications?.[1]?.application_number ||
            applications?.[1]?.product_number ||
            applications[1]?.vin ||
            applications?.[1]?.yj_code) as string
        );
      }
    }
  }, []);

  const handleLabelComparison = async () => {
    if (parentLabelSection && childLabelSection) {
      let oldSection = null;
      let newSection = null;
      // sort the data; older at parent .newer at child
      // only do this if mode.sortOnDate = true
      const sort = mode.sortOnDate !== false && !switchTriggered;
      if (sort) {
        oldSection =
          parentLabelSection.approvalDate < childLabelSection.approvalDate
            ? JSON.parse(JSON.stringify(parentLabelSection))
            : JSON.parse(JSON.stringify(childLabelSection));

        newSection =
          parentLabelSection.approvalDate < childLabelSection.approvalDate
            ? JSON.parse(JSON.stringify(childLabelSection))
            : JSON.parse(JSON.stringify(parentLabelSection));
      } else {
        oldSection = JSON.parse(JSON.stringify(parentLabelSection));
        newSection = JSON.parse(JSON.stringify(childLabelSection));
      }
      const pdfData = {
        parent_object: await handleSignedURL(oldSection),
        child_object: await handleSignedURL(newSection)
      };

      setOldLabelSection({ ...oldSection, pdfLink: pdfData?.parent_object });
      setNewLabelSection({ ...newSection, pdfLink: pdfData?.child_object });

      const res = await getLabelComparision(pdfData);
      if (res.data.success !== null) {
        setViewerURL(res?.data?.success);
      }
    }
  };

  useEffect(() => {
    handleLabelComparison();
  }, [labelOrder]);

  const handleParentLabelSelect = (item: any, section: string) => {
    // this logic is for Child_label _dependent_on_parent

    if (mode.child_labelList_dependent_on_parent) {
      const copyChildLabelList = JSON.parse(JSON.stringify(childLabelList));
      const removedChildLabelList = copyChildLabelList.filter((ele: any) => {
        return !(
          ele.submission_num === item.submission_num &&
          ele.approval_date === item.approval_date &&
          ele.description === item.description
        );
      });
      setChildLabels(removedChildLabelList);
    }

    // this logic is for child_label _dependent_on_parent   ENDS

    // this logic is for section binding
    if (mode.section_binding) {
      if (section && childLabelSection) {
        // As the parent section has changed, so update the childLabelsection
        // we have to update the sectionPdfLink of the childLabelSection
        const childLabelApprovalDate = childLabelSection?.approvalDate;
        const childLabelSubNum = childLabelSection?.submissionNum;
        const childLabelSubType = childLabelSection?.submissionType;
        const childLabelDescription = childLabelSection?.description;
        const selectedLabel: any = childLabelList.find(
          (ele: any) =>
            ele.approval_date === childLabelApprovalDate &&
            ele.submission_num === childLabelSubNum &&
            ele.submission_type === childLabelSubType &&
            ele.description === childLabelDescription
        );
        // @ts-ignore
        setChildLabelSection(prev => ({
          // @ts-ignore
          ...prev,
          // @ts-ignore,
          section,
          pdfLink:
            section !== 'all_sections'
              ? selectedLabel.label.sections.find(
                  (sectionItem: any) => sectionItem.section === section
                )?.s3_file_path
              : selectedLabel?.label?.s3_file_path
        }));
      }
    }
    // this logic is for section binding           ENDS

    //  if we want default section to be all_sections
    const sectionToAdd = !section && mode.default_section_all_section ? 'all_sections' : section;
    const labelData: SelectedSectionProps = {
      section: sectionToAdd,
      pdfLink:
        sectionToAdd !== 'all_sections'
          ? item?.label?.sections.find((sectionItem: any) => sectionItem.section === sectionToAdd)
              ?.s3_file_path
          : item?.label?.s3_file_path,
      submissionType: item.submission_type,
      submissionNum: item.submission_num,
      approvalDate: item.approval_date,
      description: item.description,
      source: selectedApplications[0]?.source ? selectedApplications[0]?.source : '',
      sourceType: item.source,
      application_type:
        selectedApplications[0]?.source.toLocaleLowerCase() === 'us'
          ? selectedApplications[0]?.application_type
          : '',
      application_number: selectedApplications[0]?.application_number
        ? selectedApplications[0]?.application_number
        : selectedApplications[0]?.product_number,
      application_name: selectedApplications[0]?.application_name
        ? selectedApplications[0]?.application_name
        : '',
      applicationLabels: selectedApplications[0]?.labels ? selectedApplications[0]?.labels : [],
      center: item?.label?.center ?? ''
    };
    setParentLabelSection(labelData);
  };

  const handleChildLabelSelect = async (item: any, section: string) => {
    // this logic binds section from parent when we select a new label
    let sectionFromPrent =
      !section && parentLabelSection?.section ? parentLabelSection?.section : section;
    if (!sectionFromPrent) {
      sectionFromPrent = 'all_sections';
    }
    const sectionNotFromParent =
      !section && mode.default_section_all_section ? 'all_sections' : section;

    // this logic binds section from parent when we select a new label END
    const selectedSection = mode.section_binding ? sectionFromPrent : sectionNotFromParent;
    const labelData: SelectedSectionProps = {
      section: selectedSection,
      pdfLink:
        selectedSection !== 'all_sections'
          ? item?.label?.sections.find(
              (sectionItem: any) => sectionItem.section === selectedSection
            )?.s3_file_path
          : item?.label?.s3_file_path,
      submissionType: item.submission_type,
      submissionNum: item.submission_num,
      approvalDate: item.approval_date,
      description: item.description,
      source: selectedApplications[1]?.source ? selectedApplications[1]?.source : '',
      sourceType: item.source,
      application_type:
        selectedApplications[1]?.source.toLocaleLowerCase() === 'us'
          ? selectedApplications[1]?.application_type
          : '',
      application_number: selectedApplications[1]?.application_number
        ? selectedApplications[1]?.application_number
        : selectedApplications[1]?.product_number,
      application_name: selectedApplications[1]?.application_name
        ? selectedApplications[1]?.application_name
        : '',
      applicationLabels: selectedApplications[1]?.labels ? selectedApplications[1]?.labels : [],
      center: item?.label?.center ?? ''
    };

    // this logic is for section binding
    if (mode.section_binding) {
      if (section) {
        // section is present in child ---> update parentChildSection
        // we have to update the sectionPdfLink of the parentLabelSection
        const parentLabelApprovalDate = parentLabelSection?.approvalDate;
        const parentLabelSubNum = parentLabelSection?.submissionNum;
        const parentLabelSubType = parentLabelSection?.submissionType;
        const parentLabelDescription = parentLabelSection?.description;
        const selectedLabel: any = parentLabelList.find(
          (ele: any) =>
            ele.approval_date === parentLabelApprovalDate &&
            ele.submission_num === parentLabelSubNum &&
            ele.submission_type === parentLabelSubType &&
            ele.description === parentLabelDescription
        );
        // @ts-ignore
        setParentLabelSection(prev => ({
          ...prev,
          pdfLink:
            section !== 'all_sections'
              ? selectedLabel?.label?.sections.find(
                  (sectionItem: any) => sectionItem.section === section
                )?.s3_file_path
              : selectedLabel?.label?.s3_file_path,
          section
        }));
      }
    }
    // this logic is for section binding END
    setChildLabelSection(labelData);
  };

  const downloadFile = () => {
    try {
      const identifierInURL = viewURL?.split('/')[7];
      const identifier = identifierInURL?.split('?')[0];
      getComparisonFile(identifier);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  const labelMappings = {
    us: (selectedApplication: any) => selectedApplication?.application_name,
    ca: (selectedApplication: any) => selectedApplication?.application_name,
    hpra: (selectedApplication: any) => selectedApplication?.application_name,
    jp: (selectedApplication: any) => selectedApplication?.application_name,
    hma: (selectedApplication: any) => selectedApplication?.application_name,
    au: (selectedApplication: any) => selectedApplication?.application_name,
    mhra: (selectedApplication: any) => selectedApplication?.application_name,
    chn: (selectedApplication: any) => selectedApplication?.application_name,
    default: (selectedApplication: any) =>
      (selectedApplication?.center === 'ema-who' && 'EMEA-H-W-') || 'EMEA-H-C-'
  };

  const getApplicationString = (application: any) => {
    let applicationString = '';
    const source = application.source.toLowerCase() as keyof typeof labelMappings;
    const labelFunction = labelMappings[source] || labelMappings.default;
    // for us we need to show the trade name as header of pdf
    if (
      source === ('us' as keyof typeof labelMappings) ||
      source === ('eu' as keyof typeof labelMappings)
    ) {
      if (!(mode.parentRld && mode.childRld)) {
        return `${application?.application_name || ''} - ${application?.submissionType}-${
          application?.submissionNum
        } - ${application?.approvalDate || ''}`;
      }
    }
    applicationString = `${labelFunction(application)} - ${application?.submissionType}-${
      application?.submissionNum
    } - ${application?.approvalDate}`;
    return applicationString;
  };

  const handleSwapLabels = () => {
    // Swap the order of labels in the state
    const currentOrder = [...labelOrder]; // Create a copy of the array to avoid mutating state directly
    [currentOrder[0], currentOrder[1]] = [currentOrder[1], currentOrder[0]];
    setChildLabelSection(parentLabelSection);
    setParentLabelSection(childLabelSection);
    setOldLabelSection(newLabelSection);
    setNewLabelSection(oldLabelSection);
    setLabelOrder(currentOrder);
    setSwitchTriggered(true);
  };
  if (openReporting) {
    return (
      <ReportingDrawer
        isDrawerOpen={openReporting}
        closeDrawer={() => {
          setOpenReporting(false);
        }}
        isNavBar
      />
    );
  }
  return (
    <Drawer
      open={open}
      anchor='bottom'
      onClose={onClose}
      PaperProps={{
        style: {
          height: '98vh',
          width: '100vw',
          backgroundColor: '#fff',
          maxWidth: 'none',
          maxHeight: 'none',
          borderRadius: '8px 8px 0px 0px'
        }
      }}>
      <Stack direction='row' sx={comparisonDialogStyles.headerWrapper}>
        {viewURL && !(mode?.parentRld && mode?.childRld) && (
          <Stack
            direction='row'
            alignItems='center'
            spacing={0.75}
            onClick={() => {
              setViewerURL(undefined);
              setParentLabelSection(undefined);
              setChildLabelSection(undefined);
              setShowSummary(false);
            }}
            sx={comparisonDialogStyles.changeLabelsWrapper}>
            <LeftArrowIcon sx={comparisonDialogStyles.leftArrowIcon} />
            <Typography sx={comparisonDialogStyles.changeLabelsText}>Change labels</Typography>
          </Stack>
        )}
        {showBackButton && !viewURL && onBack && (
          <Stack
            direction='row'
            alignItems='center'
            spacing={0.75}
            onClick={onBack}
            sx={comparisonDialogStyles.changeProductsWrapper}>
            <LeftArrowIcon sx={comparisonDialogStyles.leftArrowIcon} />
            <Typography sx={comparisonDialogStyles.changeLabelsText}>Change products</Typography>
          </Stack>
        )}
        <Stack spacing={1} sx={comparisonDialogStyles.enhancedComparisonWrapper}>
          <Typography variant='h3' fontWeight='bold' color='white.main'>
            Enhanced Comparison
          </Typography>
        </Stack>
        <Tooltip title='Close'>
          <IconButton
            aria-label='delete'
            onClick={onClose}
            size='small'
            sx={comparisonDialogStyles.closeIconButton}>
            <HighlightOffOutlinedIcon fontSize='inherit' sx={comparisonDialogStyles.closeIcon} />
          </IconButton>
        </Tooltip>
      </Stack>
      <DialogContent sx={{ p: 0 }}>
        <Grid container spacing={2}>
          {/* comparison pdf viewer grid */}
          <Grid item xs={showSummary ? 8 : 12}>
            <Box>
              {/* eslint-disable-next-line no-nested-ternary */}
              {viewURL ? (
                <DraftableComparisonView
                  viewerURL={viewURL}
                  downloadFile={downloadFile}
                  parentApplicationNumber={getApplicationString(oldLabelSection)}
                  parentApplicationName={
                    oldLabelSection?.application_name ? oldLabelSection?.application_name : ''
                  }
                  // @ts-ignore
                  childApplicationNumber={getApplicationString(newLabelSection)}
                  childApplicationName={
                    newLabelSection?.application_name ? newLabelSection.application_name : ''
                  }
                  showFooter
                  parentApplicationSource={oldLabelSection?.source?.toLowerCase()}
                  childApplicationSource={newLabelSection?.source?.toLowerCase()}
                  parentApplicationLabels={oldLabelSection?.applicationLabels}
                  childApplicationLabels={newLabelSection?.applicationLabels}
                  handleSwapLabels={handleSwapLabels}
                  isSwitchOptionAvailable
                  fullWidth={false}
                  enableChatRia
                  allSectionSelected={
                    oldLabelSection?.section === 'all_sections' ||
                    newLabelSection?.section === 'all_sections'
                  }
                  parentApplication={newLabelSection}
                  childApplication={oldLabelSection}
                  setOpenReporting={setOpenReporting}
                  comparsionType='label'
                />
              ) : !(mode?.parentRld && mode?.childRld) ? (
                <Box sx={comparisonDialogStyles.labelListGridWrapper}>
                  <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={6} height='100%' maxWidth='620px !important'>
                      <LabelList
                        isLoading={
                          labelOrder[0] === 'parent' ? parentLabelLoading : childLabelLoading
                        }
                        selectedApplication={
                          labelOrder[0] === 'parent'
                            ? selectedApplications?.[0]
                            : selectedApplications?.[1]
                        }
                        onLabelSelect={
                          labelOrder[0] === 'parent'
                            ? handleParentLabelSelect
                            : handleChildLabelSelect
                        }
                        labelList={
                          // eslint-disable-next-line no-nested-ternary
                          labelOrder[0] === 'parent'
                            ? parentLabelList
                            : mode.child_labelList_dependent_on_parent
                            ? childLabels
                            : childLabelList
                        }
                        selectedLabel={
                          labelOrder[0] === 'parent' ? parentLabelSection : childLabelSection
                        }
                        errorMessage={
                          labelOrder[0] === 'parent'
                            ? parentLabelLoadingError
                            : childLabelLoadingError
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      alignItems='flex-start'
                      height='100%'
                      maxWidth='620px !important'>
                      <LabelList
                        isLoading={
                          labelOrder[1] === 'child' ? childLabelLoading : parentLabelLoading
                        }
                        selectedApplication={
                          labelOrder[1] === 'child'
                            ? selectedApplications?.[1]
                            : selectedApplications?.[0]
                        }
                        onLabelSelect={
                          labelOrder[1] === 'child'
                            ? handleChildLabelSelect
                            : handleParentLabelSelect
                        }
                        labelList={
                          // eslint-disable-next-line no-nested-ternary
                          labelOrder[1] === 'child'
                            ? mode.child_labelList_dependent_on_parent
                              ? childLabels
                              : childLabelList
                            : parentLabelList
                        }
                        selectedLabel={
                          labelOrder[1] === 'child' ? childLabelSection : parentLabelSection
                        }
                        errorMessage={
                          labelOrder[1] === 'child'
                            ? childLabelLoadingError
                            : parentLabelLoadingError
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      {!viewURL && !(mode?.parentRld && mode?.childRld) && (
        <DialogActions sx={comparisonDialogStyles.dialogActionsContainer}>
          <Stack>
            <Button
              onClick={handleLabelComparison}
              disabled={!(parentLabelSection?.pdfLink && childLabelSection?.pdfLink)}
              sx={comparisonDialogStyles.compareButton}>
              Compare
              {/* </Button> */}
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Drawer>
  );
};

ComparisonDialog.defaultProps = {
  showBackButton: false,
  onBack: undefined
};

export default React.memo(ComparisonDialog);
